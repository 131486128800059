import * as React from "react";
import FeatherIcon from "feather-icons-react";

import styles from "./icon.module.css";

export const Icon = ({ icon, text, apartment }) => {
  if (apartment) {
    return (
      <div className={styles.container}>
        <i style={{color: "#c1a178", fontSize: 25 }} className={`fa ${icon}`} aria-hidden="true" size={32}></i>
        <p>{text}</p>

      </div>
    );
  }

  return (
    <div className={styles.container}>
      <FeatherIcon icon={icon} color="#c1a178" size={32} />
      <p>{text}</p>
    </div>
  );
};

export default Icon;
